/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}

html body {
  background-color: #767676;
}
.bg {
  background: url("de784930e3f66b9c");
  height: 100%;
  background-attachment: fixed;
  background-repeat: no-repeat;
  background-size: cover;
  margin-left: auto;
  margin-right: auto;
  background-position-x: 48%;
}
.backdrop {
  background-color: #fff;
}
.bottomContainer {
  display: flex;
  justify-content: space-around;
  flex-flow: row wrap;
}
.bottomContainerItem1 {
  width: 50%;
}
.bottomContainerItem1 h3 {
  font-size: 4rem;
  text-align: center;
  color: #1134e1;
  text-decoration: underline;
  font-style: italic;
}
.bottomContainerItem1 p {
  text-indent: 5%;
  font-size: 3rem;
  text-align: center;
  color: #fff;
}
.bottomContainerItem2 {
  display: flex;
  flex-wrap: wrap;
  max-width: 50%;
  width: 50%;
  justify-content: center;
}
#logoFixed {
  position: fixed;
  left: 0;
  top: 0;
  max-width: 13%;
}
@media (max-width: 1000px) {
  .bg {
    background: url("34defa848ef92ad8");
    height: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
    background-position-x: 48%;
  }
  .bottomContainerItem1 h3 {
    font-size: 2rem;
    text-align: center;
    color: #36f;
    text-decoration: underline;
    font-style: italic;
  }
  .bottomContainerItem1 p {
    text-indent: 5%;
    font-size: 2rem;
    text-align: center;
    color: #fff;
  }
}
@media (max-width: 600px) {
  .bg {
    background: url("34defa848ef92ad8");
    height: 50%;
    background-repeat: no-repeat;
    background-size: contain;
    margin-left: auto;
    margin-right: auto;
    background-position-x: 48%;
  }
  .bottomContainerItem1 h3 {
    font-size: 2rem;
    text-align: center;
    color: #36f;
    text-decoration: underline;
    font-style: italic;
  }
  .bottomContainerItem1 p {
    text-indent: 5%;
    font-size: 1rem;
    text-align: center;
    color: #fff;
  }
}
.servicesContent {
  background-color: #767676;
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
}
.servicesContent img {
  background-color: #fff;
  margin: 5px;
  padding: 10px;
  border-radius: 40px;
  width: 300px;
  height: 400px;
}
.bg2 {
  background: url("267eda5809d27461");
  height: 70%;
  background-repeat: no-repeat;
  background-size: contain;
  width: 80%;
  margin-left: auto;
  margin-right: auto;
  background-position-x: 48%;
}
.empty2 {
  background-color: #000;
  height: auto;
}
.serviceImages {
  background-color: #fff;
  height: 60%;
  display: flex;
  justify-content: space-evenly;
}
footer {
  background: #1134e1;
  height: auto;
  width: 100%;
  padding-top: 20px;
  color: #fff;
}
.footer-content {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
}
.footer-content h3 {
  font-size: 2.1rem;
  font-weight: 500;
  text-transform: capitalize;
  line-height: 1rem;
}
.footer-content p {
  max-width: 500px;
  margin: 10px auto;
  line-height: 28px;
  font-size: 14px;
  color: #cacdd2;
}
.socials {
  list-style: none;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 0 1rem 0;
}
.socials ul {
  flex-wrap: wrap;
  text-align: center;
}
.socials li {
  margin: 0 10px;
}
.socials a {
  text-decoration: none;
  color: #fff;
  border: 1.1px solid #fff;
  padding: 5px;
  border-radius: 50%;
}
.socials a i {
  font-size: 1.1rem;
  width: 20px;
  transition: color 0.4s ease;
}
.socials a:hover i {
  color: #0ff;
}
.footer-bottom {
  background: #000;
  width: 100vw;
  padding: 20px;
  padding-bottom: 20px;
  text-align: center;
}
.footer-bottom p {
  float: left;
  font-size: 14px;
  word-spacing: 2px;
  text-transform: capitalize;
}
.footer-bottom p a {
  color: #44bae8;
  font-size: 16px;
  text-decoration: none;
}
.footer-bottom span {
  text-transform: uppercase;
  opacity: 0.4;
  font-weight: 200;
}
.footer-menu {
  float: right;
}
.footer-menu ul {
  display: flex;
}
.footer-menu ul li {
  padding-right: 10px;
  display: block;
}
.footer-menu ul li a {
  color: #cfd2d6;
  text-decoration: none;
}
.footer-menu ul li a:hover {
  color: #27bcda;
}
@media (max-width: 800px) {
  .empty2 {
    height: auto;
  }
}
@media (max-width: 500px) {
  .footer-menu ul {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .empty2 {
    height: auto;
  }
  .servicesContent img {
    display: block;
    background-color: #fff;
    margin: 5px auto;
    padding: 10px;
    border-radius: 40px;
    width: 90%;
  }
  .socials ul {
    text-align: center;
  }
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}
.scrolling-active {
  background-color: #fff;
  box-shadow: 0 3px 1rem rgba(0,0,0,0.1);
}
.navbar {
  background-color: #000;
  position: static;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  left: 0;
  right: 0;
  padding: 1% 1% 1% 1%;
  font-size: 40px;
}
.bgColor {
  background-color: #000;
  color: #000;
  border-bottom: 1px solid #d3d3d3;
  transition: all 1s;
  width: 70%;
  margin: 0 auto;
}
.navbar ul {
  display: flex;
  list-style: none;
  justify-content: space-around;
  width: 50%;
  margin: 0 auto;
}
li {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 25px;
  padding: 5px;
  border-radius: 10px;
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px #000, -1px 0 0 #000;
}
li a {
  text-decoration: none;
  color: #fff;
  font-size: 30px;
}
@media (max-width: 800px) {
  .navbar {
    position: static;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    left: 0;
    right: 0;
    font-size: 40px;
  }
  .bgColor {
    background-color: #000;
    color: #000;
    border-bottom: 1px solid #d3d3d3;
    transition: all 1s;
    width: 98%;
    margin: 0 auto;
  }
  .logo {
    width: 100%;
    text-align: center;
  }
  .navbar ul {
    flex-wrap: wrap;
    text-align: center;
  }
  li {
    width: 100%;
    margin: 1px;
  }
}
.slbodybg {
  background-color: #fff;
}
.spacebetweenmapandpics {
  background-color: #fff;
  height: 10%;
  width: 1200;
}
.center {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 50%;
}
.slbg {
  background-color: #000;
  height: 10%;
  width: 100%;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}
.slbg2 {
  background-color: #fff;
  overflow: auto;
  justify-content: space-evenly;
  margin-right: auto;
  margin-left: auto;
  display: flex;
  flex-wrap: wrap;
}
.slbg2 img {
  width: 20%;
  padding: 5px;
}
.responsiveIframe {
  width: 1200px;
  height: 700px;
  border: none;
}
.map {
  display: flex;
  justify-content: center;
}
@media (min-width: 2100px) {
  .map {
    display: flex;
    justify-content: center;
  }
}
@media (max-width: 1000px) {
  .responsiveIframe {
    width: 500px;
    height: 350px;
    border: none;
  }
  .map {
    display: flex;
    justify-content: center;
  }
  .spacebetweenmapandpics {
    height: auto;
    width: 350px;
  }
  .slbg {
    background-color: #000;
    height: auto;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
  }
}
.slbg3 {
  background-color: #fff;
  overflow: auto;
  width: 65%;
  justify-content: center;
  margin-right: auto;
  margin-left: auto;
}
.tableCenter {
  margin-left: auto;
  margin-right: auto;
}
th {
  text-align: right;
  font-size: 30px;
  border: 2px solid #000;
}
td {
  font-size: 30px;
  padding-left: 10px;
  border: 2px solid #000;
}
.hoo {
  background-color: transparent;
  opacity: 1;
  text-align: center;
}
.hoo p {
  font-size: 40px;
  padding-bottom: 10px;
  text-decoration: underline;
}
.contact {
  padding-top: 20px;
  font-size: 2rem;
}
.contactemail {
  font-size: 2rem;
}
.bottombg4 {
  max-width: 70%;
  background-color: #fff;
  background-repeat: no-repeat;
  background-size: cover;
  margin: 0 auto;
  height: 115%;
}
.navbar2 {
  position: fixed;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  left: auto;
  right: auto;
  font-size: 40px;
  background-color: #000;
  width: 70%;
}
#map {
  height: 400px;
/* The height is 400 pixels */
  width: 70%;
  margin-left: auto;
  margin-right: auto;
/* The width is the width of the web page */
}
.findUs {
  font-size: 2rem;
  margin-top: 5%;
}
.empty3 {
  background-color: #000;
  height: auto;
}
@media (max-width: 1000px) {
  .empty3 {
    background-color: #000;
    height: auto;
  }
  .slbg3 {
    background-color: #fff;
    overflow: auto;
    width: 100%;
    justify-content: center;
    margin-right: auto;
    margin-left: auto;
  }
  .contact {
    padding-top: 20px;
    font-size: 2rem;
  }
  .contactemail {
    font-size: 1.4rem;
  }
}
.servicebg {
  background-color: #fff;
}
.yes {
  display: grid;
  padding: 2rem;
  grid-template-columns: 300px 1fr;
  gap: 1rem;
  align-items: center;
  max-width: 800px;
  margin: 0 auto;
  font: 500 100%/1.5 system-ui;
}
.yes img {
  max-width: 100%;
  height: auto;
}
@media (max-width: 650px) {
  .yes {
    display: block;
    font-size: 80%;
  }
  .yes p {
    position: relative;
    margin: -3rem 0 2rem 1rem;
    padding: 1rem;
    background-color: rgba(18,140,255,0.5);
    font-weight: bold;
  }
  .yes p:nth-last-of-type(2) {
    position: relative;
    margin: 2rem 0 -3rem 1rem;
    padding: 1rem;
    background-color: rgba(18,140,255,0.5);
    font-weight: bold;
  }
}
